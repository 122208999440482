<template>
    <div class="main-subscriptions">
        <div class="subscriptions-general-container">
            <div class="subscriptions">
                <Header />
                <Wrapper>
                    <div class="subscriptions-general">
                        <h1 class="subscriptions-header">{{ $t('generalTabs.subscriptions') }}</h1>
                        <div class="subscriptions-plans">
                            <div class="subscriptions-plans-item" v-for="pack in packages" :key="pack.name">
                                <div>
                                    <div class="plans-item-header">
                                        <h1 class="plans-item-name">
                                            {{ planHeader(pack.value, true) }}
                                        </h1>
                                        <div v-if="pack.days === 180" class="plans-item-most-popular">
                                            {{ $t('generalTabs.mostPopular') }}
                                        </div>
                                    </div>
                                    <p class="plans-item-subscription">
                                        {{ planHeader(pack.value).text }}
                                    </p>
                                </div>
                                <div class="subscriptions-plans-bootom-container">
                                    <p class="plans-item-days">{{ pack.days }} Days</p>
                                    <h1 class="plans-item-price">${{ pack.price }}</h1>
                                    <button @click="getPaymentPage(pack.value)" class="plans-item-btn"
                                        :class="pack.days === 180 ? 'popular' : ''">{{ $t('generalTabs.choosePlan')
                                        }}</button>
                                    <button v-if="planHeader(pack.value, true) !== 'Starter'" class="save-btn">{{ $t('generalTabs.save') }}
                                        {{ planHeader(pack.value).savePercent }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wrapper>
            </div>
            <div class="subscriptions-table">
                <Table :headers="headers" :items="translatedSubscriptionsObj"
                    :isTransactions="false" :headerText="'generalTabs.subHistory'" :helperText="'generalTabs.haveASubscription'"/>
            </div>
        </div>
        <Envelope />
    </div>
</template>

<script>
export default {
    props: {
        packages: Array,
        getPaymentPage: Function,
        handleRefreshTransactions: Function,
        headers: Array,
        translatedSubscriptionsObj: Array
    },
    components: {
        Wrapper: () => import('@/components/Wrapper.vue'),
        Header: () => import('@/components/SideNavPc.vue'),
        Table: () => import('@/views/Transactions/PremiumClub/PremiumClubTable.vue'),
        Envelope: () => import('@/components/Envelope.vue')
    },
    methods: {
        planHeader(id, isHeader) {
            let planName, planText, savePercent;
            switch (id) {
                case 2:
                case 105:
                case 20001:
                    planName = this.$t('generalTabs.starter');
                    planText = this.$t('generalTabs.starterText');
                    savePercent = '';
                    break;
                case 3:
                case 106:
                case 20002:
                    planName = this.$t('generalTabs.growth');
                    planText = this.$t('generalTabs.growthText');
                    savePercent = '29%';
                    break;
                case 4:
                case 107:
                case 20003:
                    planName = this.$t('generalTabs.elite');
                    planText = this.$t('generalTabs.eliteText');
                    savePercent = '39%';
                    break;
                case 5:
                case 108:
                case 20004:
                    planName = this.$t('generalTabs.professional');
                    planText = this.$t('generalTabs.professionalText');
                    savePercent = '49%';
                    break;
                default:
                    // Handle default case here if necessary
                    break;
            }
            if (isHeader) {
                return planName;
            } else {
                return { name: planName, text: planText, savePercent: savePercent };
            }
        }
    }
}
</script>

<style scoped>
h1,
p,
button,
input,
label,
a {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.main-subscriptions {
    display: flex;
    justify-content: space-between;
}

.subscriptions-table {
    margin-top: 32px;
}


.subscriptions {
    width: 100%;
    width: fit-content;
}

.subscriptions-general {
    width: inherit;
}

.subscriptions-header {
    font-size: 20px;
    font-weight: 600;
    color: white;
}

.subscriptions-plans {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 24px;
    width: min-content;
}

.subscriptions-plans-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 32px 16px;
    border-radius: 8px;
    background-color: #141417;
    min-width: 247px;
    margin-right: 16px;
    transition: 0.2s all;
}

.subscriptions-plans-item:hover {
    background-color: #18191D;
    box-shadow: 0px 7px 12px 0px #1414177A;
}

.subscriptions-plans-item:last-child {
    margin-right: 0;
}

.plans-item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.plans-item-most-popular {
    border-radius: 24px;
    padding: 5px 12px;
    font-size: 11px;
    font-weight: 400;
    line-height: 13.86px;
    color: white;
    background-color: #4E4BCD;
}

.plans-item-name {
    font-size: 20px;
    line-height: 25.2px;
    font-weight: 600;
}

.plans-item-subscription {
    font-size: 14px;
    line-height: 19.5px;
    font-weight: 400;
    padding-bottom: 12px;
    color: rgba(255, 255, 255, 0.8);
}

.plans-item-days {
    font-size: 14px;
    line-height: 17.64px;
    font-weight: 500;
    padding-top: 12px;
    margin-bottom: 18px;
    border-top: 1px dashed #FFFFFF1A;
}

.plans-item-price {
    font-size: 32px;
    line-height: 40.32px;
    font-weight: 600;
    margin-bottom: 8px;
}

.plans-item-paid {
    font-size: 13px;
    line-height: 16.38px;
    font-weight: 400;
    margin-bottom: 18px;
    color: #FFFFFF99;
}

.plans-item-btn {
    font-size: 14px;
    line-height: 17.64px;
    font-weight: 600;
    color: #4E4BCD;
    padding: 12px 0;
    width: 100%;
    background-color: #141417;
    border-radius: 8px;
    border: 1px solid #4E4BCD;
    box-shadow: 0px 7px 12px 0px #4E4BCD14;
}

.popular {
    background-color: #4E4BCD;
    color: white;
    box-shadow: 0px 7px 12px 0px #4E4BCD52;
}

.subscriptions-plans-bootom-container {
    position: relative;
}

.save-btn::before {
    content: '';
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
    border-width: 6px 6px 0;
    border-style: solid;
    border-color: #14B437 transparent transparent;
}

.save-btn {
    position: absolute;
    bottom: -20px;
    right: 30%;
    background-color: #14B437;
    font-size: 11px;
    font-weight: 600;
    line-height: 13.86px;
    padding: 6px 11.5px;
    border-radius: 50px;
}

@media only screen and (max-width: 1500px) {
    .subscriptions-general-container {
        width: 70%;
    }

    .subscriptions {
        width: 100%;
    }

    .subscriptions-plans {
        flex-wrap: wrap;
        width: auto;
    }

    .subscriptions-plans-item {
        width: 45%;
        margin: 0 0 20px 0;
    }
}

@media only screen and (max-width: 1090px) {
    .main-subscriptions {
        flex-direction: column;
        align-items: center;
    }

    .subscriptions-general-container {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .subscriptions-plans {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .subscriptions-plans-item {
        margin: 16px 0 0 0;
    }
}
</style>